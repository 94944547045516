
import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { createUseStyles } from 'react-jss'

type PhotoNode = {
  id: string;
  slug: string;
  name: string;
  image: {
    childImageSharp: {
      fixed: any;
      fluid: any
    }
  }
}


const useStyles = createUseStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridAutoRows: '200px',
    gridGap: 8,
    paddingBottom: 80
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,.35)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalImgWrap: {
    padding: '1rem',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
const MasonryGallery = (props: { photoNodes: Array<PhotoNode> }) => {
  const c = useStyles()
  const [fgPicture, setFgPicture] = useState<any>(null)

  const close = () => setFgPicture(null)
  return (
    <div className={c.root} style={{}} >
      {props.photoNodes.map((x) =>
        <MasonryGalleryItem key={x.id} {...x} setFgPicture={setFgPicture} />
      )}
      {fgPicture && <GalleryModal picture={fgPicture} close={close} />}
    </div>
  )
}

const GalleryModal = (props: { picture: any; close: () => void }) => {
  const c = useStyles()
  return (
    <div className={c.overlay} onClick={props.close}>
      <div className={c.modalImgWrap}>
        <Image
          fluid={props.picture}
          style={{ float: "left", width: props.picture.aspectRatio > 1 ? '80vw' : '40vw' }}
        />
      </div>
    </div >
  )

}
const MasonryGalleryItem = (props: PhotoNode & { setFgPicture: (x: any) => void }) => {
  const c = useStyles()
  const fixed = props.image.childImageSharp.fixed
  const { height, width } = fixed
  const gridRowEnd = `span ${height > width ? 2 : 1}`

  return (
    <div className={c.imgWrapper} style={{ gridRowEnd }} onClick={() => props.setFgPicture(props.image.childImageSharp.fluid)}>
      <Image
        fixed={props.image.childImageSharp.fixed}
        alt={props.name}
      />
    </div>
  )
}

const MasonryGalleryWrapper = () => (
  <StaticQuery
    query={graphql`
        query {
          allPhotosJson {
            nodes {
              id
              slug
              name
              image {
                childImageSharp {
                  fixed(width: 250){
                    ...GatsbyImageSharpFixed
                  }
                  fluid{
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
    render={data =>
      <MasonryGallery photoNodes={data.allPhotosJson.nodes} />
    } />
)

export default MasonryGalleryWrapper