
import React from 'react'
import { createUseStyles } from 'react-jss'

import Layout, { LinkAction } from '../components/Layout'
import MasonryGallery from '../components/MasonryGallery'


const links: Array<LinkAction> = [
  { txt: 'video', to: '/Video' }
]

const useStyles = createUseStyles(theme => ({
  root: {
    paddingTop: '1.2rem',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1.2rem',
    paddingLeft: '1.2rem'
  },
  title: {
    textAlign: 'center',
    marginBottom: '1rem'

  },
}))

const Photos = () => {
  const c = useStyles()
  return (
    <Layout links={links}>
      <div className={c.root}>
        <h1 className={c.title}>Welcome to the gallery</h1>
        <MasonryGallery />
      </div>
    </Layout>
  )
}


export default Photos
